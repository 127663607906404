import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const Home = lazy(() => import('../views/Home/'));
const Tests = lazy(() => import('../views/Tests/'));

const InitRoutes = () => (
  <Suspense
    fallback={
      <div className="text-center pt-5">
        <span>Loading...</span>
      </div>
    }
  >
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/home" exact component={Home} />
      <Route path="/tests" exact component={Tests} />
      <Redirect to="/" />
    </Switch>
  </Suspense>
);

export default InitRoutes;
